import ApiService from '../apiService';
import { LocalStorage } from '../../common/localStorage';
import trainingStore from '../../store/trainingStore';
import { TRAINING_CHAPTER_TYPE } from '../../store/constants';
// import Binder from '../../common/binder';

class Common {
  constructor() {
    // Binder.bind(this, Common);
    this.apiService = new ApiService('/api', (method, url, params, body, headers) => {
      const authToken = 'Bearer bc-pre_dev-apikey';
      return {
        method,
        url,
        params,
        body,
        headers: LocalStorage.USER_UID
          ? {
              'PLAYER-UUID': `${LocalStorage.GAME_UID}:${LocalStorage.USER_UID}`,
              // Authorization нужен для созданий игр через /start, это нужно только для dev стенда
              Authorization: authToken,
            }
          : { Authorization: authToken },
      };
    });
  }

  createGame(
    shopCount,
    nurseryCount,
    withBots,
    seasonMinuteDuration,
    initialMoneyShop,
    initialMoneyNursery,
    houseHoldCostShop,
    houseHoldCostNursery,
    absenceTimeout,
    withSupportBot,
    cityBaseQuotaMin,
    cityBaseQuotaMax,
    initialMoneySingleShopBot,
    initialMoneySingleNurseryBot,
    botSpectate,
    withHideInformation,
    withCredit,
    consumerCreditRate,
    investmentCreditRate,
    specialCreditRate,
    possibleCreditForNursery,
    possibleCreditForShop,
    withLtd,
    withChanceBankruptcy,
    chancePercent,
    maxPossibleFine,
    bankGuarantee,
    withRandomGameEnd,
    randomGameEndBegin,
    withDelegationLtd,
    withDefaultDeal,
  ) {
    return this.apiService.post(
      'create',
      {},
      {
        initial_money: {
          shop: initialMoneyShop,
          nursery: initialMoneyNursery,
        },
        initial_money_single_shop_bot: initialMoneySingleShopBot,
        initial_money_single_nursery_bot: initialMoneySingleNurseryBot,
        with_bots: withBots,
        with_support_bot: withSupportBot,
        bot_watch_enabled: botSpectate,
        absence_timeout: absenceTimeout,
        city_base_quota_min: cityBaseQuotaMin,
        city_base_quota_max: cityBaseQuotaMax,
        game_type: nurseryCount + shopCount === 1 ? 'single' : undefined,
        with_hide_information: withHideInformation,
        with_credit: withCredit,
        credit_percentages: {
          consumer: consumerCreditRate,
          investment: investmentCreditRate,
          special: specialCreditRate,
        },
        possible_credit_for_nursery: possibleCreditForNursery,
        possible_credit_for_shop: possibleCreditForShop,
        with_ltd: withLtd,
        with_default_deal: withDefaultDeal,
        with_chance_bankruptcy: withChanceBankruptcy,
        chance_percent: chancePercent,
        max_possible_fine: maxPossibleFine,
        bank_guarantee: bankGuarantee,
        players: [
          ...[...Array(nurseryCount).keys()].map((index) => {
            let number = index + 1;
            if (number < 10) {
              number = '0' + number;
            }
            return {
              uid: '00000000-0000-0000-0000-0000000000' + number,
              name: 'Питомник - ' + number,
              role: 'nursery',
            };
          }),
          ...[...Array(shopCount).keys()].map((index) => {
            let number = index + 1 + nurseryCount;
            if (number < 10) {
              number = '0' + number;
            }
            return {
              uid: '00000000-0000-0000-0000-0000000000' + number,
              name: 'Зоомагазин - ' + (index + 1),
              role: 'shop',
            };
          }),
        ],
        turn_durations: seasonMinuteDuration.split(',').map((min) => parseFloat(min) * 60),
        household_cost: {
          shop: houseHoldCostShop,
          nursery: houseHoldCostNursery,
        },
        with_random_last_turn: withRandomGameEnd,
        start_random_last_turn: randomGameEndBegin,
        with_delegation_ltd: withDelegationLtd,
      },
    );
  }

  createGameShortSeason(
    withCredit,
    consumerCreditRate,
    investmentCreditRate,
    specialCreditRate,
    possibleCreditForNursery,
    possibleCreditForShop,
  ) {
    return this.apiService.post(
      'create',
      {},
      {
        initial_money: {
          shop: 2000,
          nursery: 2000,
        },
        with_bots: true,
        players: [
          {
            uid: '00000000-0000-0000-0000-000000000003',
            name: 'Питомник - 2',
            role: 'nursery',
          },
          {
            uid: '00000000-0000-0000-0000-000000000001',
            name: 'Питомник - 1',
            role: 'nursery',
          },
          {
            uid: '00000000-0000-0000-0000-000000000002',
            name: 'Зоомагазин - 1',
            role: 'shop',
          },
          {
            uid: '00000000-0000-0000-0000-000000000004',
            name: 'Зоомагазин - 2',
            role: 'shop',
          },
        ],
        turn_durations: [...Array(13).keys()].map(() => 0.5 * 60),
        city_base_quota_min: 200,
        city_base_quota_max: 200,
        household_cost: {
          shop: 5,
          nursery: 3,
        },
        with_credit: withCredit,
        credit_percentages: {
          consumer: consumerCreditRate,
          investment: investmentCreditRate,
          special: specialCreditRate,
        },
        possible_credit_for_nursery: possibleCreditForNursery,
        possible_credit_for_shop: possibleCreditForShop,
      },
    );
  }

  createGameShop(
    withCredit,
    consumerCreditRate,
    investmentCreditRate,
    specialCreditRate,
    possibleCreditForNursery,
    possibleCreditForShop,
  ) {
    return this.apiService.post(
      'create',
      {},
      {
        game_type: 'single',
        initial_money: {
          shop: 2000,
          nursery: 2000,
        },
        with_bots: true,
        players: [
          {
            uid: '00000000-0000-0000-0000-000000000001',
            name: 'Зоомагазин - 1',
            role: 'shop',
          },
        ],
        turn_durations: [...Array(13).keys()].map(() => 10 * 60),
        city_base_quota_min: 200,
        city_base_quota_max: 200,
        household_cost: {
          shop: 5,
          nursery: 3,
        },
        with_credit: withCredit,
        credit_percentages: {
          consumer: consumerCreditRate,
          investment: investmentCreditRate,
          special: specialCreditRate,
        },
        possible_credit_for_nursery: possibleCreditForNursery,
        possible_credit_for_shop: possibleCreditForShop,
      },
    );
  }

  createGameNursery(
    withCredit,
    consumerCreditRate,
    investmentCreditRate,
    specialCreditRate,
    possibleCreditForNursery,
    possibleCreditForShop,
  ) {
    return this.apiService.post(
      'create',
      {},
      {
        game_type: 'single',
        initial_money: {
          shop: 2000,
          nursery: 2000,
        },
        with_bots: true,
        players: [
          {
            uid: '00000000-0000-0000-0000-000000000001',
            name: 'Питомник - 1',
            role: 'nursery',
          },
        ],
        turn_durations: [...Array(13).keys()].map(() => 10 * 60),
        city_base_quota_min: 200,
        city_base_quota_max: 200,
        household_cost: {
          shop: 5,
          nursery: 3,
        },
        with_credit: withCredit,
        credit_percentages: {
          consumer: consumerCreditRate,
          investment: investmentCreditRate,
          special: specialCreditRate,
        },
        possible_credit_for_nursery: possibleCreditForNursery,
        possible_credit_for_shop: possibleCreditForShop,
      },
    );
  }

  createTeamGame(
    game_uuid,
    shopCount,
    nurseryCount,
    withBots,
    seasonMinuteDuration,
    initialMoneyShop,
    initialMoneyNursery,
    houseHoldCostShop,
    houseHoldCostNursery,
    absenceTimeout,
    withSupportBot,
    cityBaseQuotaMin,
    cityBaseQuotaMax,
    initialMoneySingleShopBot,
    initialMoneySingleNurseryBot,
    botSpectate,
    withHideInformation,
    withCredit,
    consumerCreditRate,
    investmentCreditRate,
    specialCreditRate,
    possibleCreditForNursery,
    possibleCreditForShop,
    withLtd,
    withChanceBankruptcy,
    chancePercent,
    withPrivilegesChange,
    maxPossibleFine,
    bankGuarantee,
    withRandomGameEnd,
    randomGameEndBegin,
    withDelegationLtd,
    withDefaultDeal,
  ) {
    return this.apiService.post(
      'create',
      {},
      {
        assessment_id: game_uuid,
        version: '922d41864ca039e4fa14977d1d7174d482ee817c',
        game_type: 'team',
        initial_money: {
          shop: initialMoneyShop,
          nursery: initialMoneyNursery,
        },
        params: {
          initial_money_shop: initialMoneyShop,
          initial_money_nursery: initialMoneyNursery,
          initial_money_single_shop_bot: initialMoneySingleShopBot,
          initial_money_single_nursery_bot: initialMoneySingleNurseryBot,
          absence_timeout: absenceTimeout,
          turn_durations: seasonMinuteDuration.split(',').map((min) => parseFloat(min) * 60),
          with_ltd: withLtd,
          with_chance_bankruptcy: withChanceBankruptcy,
          chance_percent: chancePercent,
          with_privileges_change: withPrivilegesChange,
          max_possible_fine: maxPossibleFine,
          bank_guarantee: bankGuarantee,
          household_cost_shop: houseHoldCostShop,
          household_cost_nursery: houseHoldCostNursery,
          with_bots: withBots,
          with_support_bot: withSupportBot,
          bot_watch_enabled: botSpectate,
          city_base_quota_min: cityBaseQuotaMin,
          city_base_quota_max: cityBaseQuotaMax,
          with_hide_information: withHideInformation,
          with_credit: withCredit,
          credit_percentages: {
            consumer: consumerCreditRate,
            investment: investmentCreditRate,
            special: specialCreditRate,
          },
          possible_credit_for_nursery: possibleCreditForNursery,
          possible_credit_for_shop: possibleCreditForShop,
          with_random_last_turn: withRandomGameEnd,
          start_random_last_turn: randomGameEndBegin,
          with_delegation_ltd: withDelegationLtd,
          with_default_deal: withDefaultDeal,
        },
        players: [
          ..._.flatten(
            [...Array(nurseryCount).keys()].map((index) => {
              let number = index + 1;
              if (number < 10) {
                number = '0' + number;
              }
              if (number === '01') {
                number = '00';
              }
              return [
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '1',
                  name: 'Captain Name',
                  role: 'captain',
                  team: 'nursery',
                  teamNum: number,
                },
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '2',
                  name: 'Marketeer Name',
                  team: 'nursery',
                  teamNum: number,
                  role: 'marketeer',
                },
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '3',
                  name: 'Sales Name',
                  team: 'nursery',
                  teamNum: number,
                  role: 'sales',
                },
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '4',
                  name: 'Supply Name',
                  team: 'nursery',
                  teamNum: number,
                  role: 'supply',
                },
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '5',
                  name: 'Doctor Name',
                  team: 'nursery',
                  teamNum: number,
                  role: 'doctor',
                },
              ];
            }),
          ),
          ..._.flatten(
            [...Array(shopCount).keys()].map((index) => {
              let number = index + 1 + nurseryCount;
              if (number < 10) {
                number = '0' + number;
              }
              return [
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '1',
                  name: 'Captain Name',
                  role: 'captain',
                  team: 'shop',
                  teamNum: number,
                },
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '2',
                  name: 'Marketeer Name',
                  team: 'shop',
                  teamNum: number,
                  role: 'marketeer',
                },
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '3',
                  name: 'Sales Name',
                  team: 'shop',
                  teamNum: number,
                  role: 'sales',
                },
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '4',
                  name: 'Supply Name',
                  team: 'shop',
                  teamNum: number,
                  role: 'supply',
                },
                {
                  uid: '00000000-0000-0000-0000-000000000' + number + '5',
                  name: 'Doctor Name',
                  team: 'shop',
                  teamNum: number,
                  role: 'doctor',
                },
              ];
            }),
          ),
        ],
      },
    );
  }

  createTeamGameTwoPlayers(game_uuid) {
    return this.apiService.post(
      'create',
      {},
      {
        assessment_id: game_uuid,
        version: '922d41864ca039e4fa14977d1d7174d482ee817c',
        game_type: 'team',
        players: [
          {
            uid: '00000000-0000-0000-0000-000000000001',
            name: 'Captain Name',
            role: 'captain',
            team: 'nursery',
            teamNum: 1,
          },
          {
            uid: '00000000-0000-0000-0000-000000000012',
            name: 'Marketeer Name',
            team: 'nursery',
            teamNum: 1,
            role: 'marketeer',
          },
          {
            uid: '00000000-0000-0000-0000-000000000013',
            name: 'Sales Name',
            team: 'nursery',
            teamNum: 1,
            role: 'sales',
          },
          {
            uid: '00000000-0000-0000-0000-000000000014',
            name: 'Supply Name',
            team: 'nursery',
            teamNum: 1,
            role: 'supply',
          },
          {
            uid: '00000000-0000-0000-0000-000000000015',
            name: 'Doctor Name',
            team: 'nursery',
            teamNum: 1,
            role: 'doctor',
          },
          {
            uid: '00000000-0000-0000-0000-000000000021',
            name: 'Captain Name',
            team: 'shop',
            teamNum: 2,
            role: 'captain',
          },
          {
            uid: '00000000-0000-0000-0000-000000000022',
            name: 'Marketeer Name',
            team: 'shop',
            teamNum: 2,
            role: 'marketeer',
          },
          {
            uid: '00000000-0000-0000-0000-000000000023',
            name: 'Sales Name',
            team: 'shop',
            teamNum: 2,
            role: 'sales',
          },
          {
            uid: '00000000-0000-0000-0000-000000000024',
            name: 'Supply Name',
            team: 'shop',
            teamNum: 2,
            role: 'supply',
          },
          {
            uid: '00000000-0000-0000-0000-000000000025',
            name: 'Doctor Name',
            team: 'shop',
            teamNum: 2,
            role: 'doctor',
          },
        ],
        params: {
          initial_money_shop: 2000,
          initial_money_nursery: 2000,
          absence_timeout: 30,
          turn_durations: [2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000],
          household_cost_shop: 5,
          household_cost_nursery: 3,
        },
      },
    );
  }

  createCorpGame(
    players,
    game_uuid,
    withBots,
    seasonMinuteDuration,
    initialMoneyShop,
    initialMoneyNursery,
    houseHoldCostShop,
    houseHoldCostNursery,
    absenceTimeout,
    withSupportBot,
    botSpectate,
    withHideInformation,
    withCredit,
    consumerCreditRate,
    investmentCreditRate,
    specialCreditRate,
    possibleCreditForNursery,
    possibleCreditForShop,
    initialMoneyCeo,
    initialMoneyTeamInCity,
    householdCostShopCeo,
    householdCostNurseryCeo,
    bigCarBuyCost,
    bigCarRentCost,
    smallCarBuyCost,
    smallCarRentCost,
    variableImportExport,
    withLtd,
    withChanceBankruptcy,
    chancePercent,
    maxPossibleFine,
    bankGuarantee,
    cityBaseMinQuota,
    cityBaseMaxQuota,
    cityCorpMinQuota,
    cityCorpMaxQuota,
    withRandomGameEnd,
    randomGameEndBegin,
    withDelegationLtd,
  ) {
    return this.apiService.post(
      'create',
      {},
      {
        assessment_id: game_uuid,
        version: '922d41864ca039e4fa14977d1d7174d482ee817c',
        game_type: 'corp',
        initial_money: {
          shop: initialMoneyShop,
          nursery: initialMoneyNursery,
        },
        params: {
          initial_money_shop: initialMoneyShop,
          initial_money_nursery: initialMoneyNursery,
          with_ltd: withLtd,
          with_chance_bankruptcy: withChanceBankruptcy,
          chance_percent: chancePercent,
          max_possible_fine: maxPossibleFine,
          bank_guarantee: bankGuarantee,
          absence_timeout: absenceTimeout,
          turn_durations: seasonMinuteDuration.split(',').map((min) => parseFloat(min) * 60),
          household_cost_shop: houseHoldCostShop,
          household_cost_nursery: houseHoldCostNursery,
          household_cost_shop_ceo: householdCostShopCeo,
          household_cost_nursery_ceo: householdCostNurseryCeo,
          with_hide_information: withHideInformation,
          with_bots: withBots,
          with_support_bot: withSupportBot,
          bot_watch_enabled: botSpectate,
          with_credit: withCredit,
          credit_percentages: {
            consumer: consumerCreditRate,
            investment: investmentCreditRate,
            special: specialCreditRate,
          },
          possible_credit_for_nursery: possibleCreditForNursery,
          possible_credit_for_shop: possibleCreditForShop,
          initial_money_ceo: initialMoneyCeo,
          initial_money_team_in_city: initialMoneyTeamInCity,
          big_car_buy_cost: bigCarBuyCost,
          big_car_rent_cost: bigCarRentCost,
          small_car_buy_cost: smallCarBuyCost,
          small_car_rent_cost: smallCarRentCost,
          variable_import_export: variableImportExport,
          city_base_quota_min: cityBaseMinQuota,
          city_base_quota_max: cityBaseMaxQuota,
          city_corp_min_quota: cityCorpMinQuota,
          city_corp_max_quota: cityCorpMaxQuota,
          with_random_last_turn: withRandomGameEnd,
          start_random_last_turn: randomGameEndBegin,
          with_delegation_ltd: withDelegationLtd,
        },
        players: players,
      },
    );
  }

  createRealTimeGame(
    shopCount,
    nurseryCount,
    game_uuid,
    withBots,
    seasonMinuteDuration,
    initialMoneyShop,
    initialMoneyNursery,
    houseHoldCostShop,
    houseHoldCostNursery,
    absenceTimeout,
    withSupportBot,
    botSpectate,
    withHideInformation,
    withCredit,
    consumerCreditRate,
    investmentCreditRate,
    specialCreditRate,
    possibleCreditForNursery,
    possibleCreditForShop,
    initialMoneyCeo,
    initialMoneyTeamInCity,
    bigCarBuyCost,
    bigCarRentCost,
    smallCarBuyCost,
    smallCarRentCost,
    variableImportExport,
    withLtd,
    maxPossibleFine,
    bankGuarantee,
    cityBaseMinQuota,
    cityBaseMaxQuota,
    cityCorpMinQuota,
    cityCorpMaxQuota,
    seasonsNumber,
    monthDuration,
    withDefaultDeal,
    chancePercent,
  ) {
    return this.apiService.post(
      'create',
      {},
      {
        assessment_id: game_uuid,
        version: '922d41864ca039e4fa14977d1d7174d482ee817c',
        game_type: 'realtime',
        initial_money: {
          shop: initialMoneyShop,
          nursery: initialMoneyNursery,
        },
        params: {
          initial_money_shop: initialMoneyShop,
          initial_money_nursery: initialMoneyNursery,
          with_ltd: withLtd,
          max_possible_fine: maxPossibleFine,
          bank_guarantee: bankGuarantee,
          absence_timeout: absenceTimeout,
          turn_durations: seasonMinuteDuration.split(',').map((min) => parseFloat(min) * 60),
          household_cost_shop: houseHoldCostShop,
          household_cost_nursery: houseHoldCostNursery,
          with_hide_information: withHideInformation,
          with_bots: withBots,
          with_support_bot: withSupportBot,
          bot_watch_enabled: botSpectate,
          with_credit: withCredit,
          credit_percentages: {
            consumer: consumerCreditRate,
            investment: investmentCreditRate,
            special: specialCreditRate,
          },
          possible_credit_for_nursery: possibleCreditForNursery,
          possible_credit_for_shop: possibleCreditForShop,
          initial_money_ceo: initialMoneyCeo,
          initial_money_team_in_city: initialMoneyTeamInCity,
          big_car_buy_cost: bigCarBuyCost,
          big_car_rent_cost: bigCarRentCost,
          small_car_buy_cost: smallCarBuyCost,
          small_car_rent_cost: smallCarRentCost,
          variable_import_export: variableImportExport,
          city_base_quota_min: cityBaseMinQuota,
          city_base_quota_max: cityBaseMaxQuota,
          city_corp_min_quota: cityCorpMinQuota,
          city_corp_max_quota: cityCorpMaxQuota,
          seasons_number: seasonsNumber,
          month_duration: monthDuration,
          with_default_deal: withDefaultDeal,
          chance_percent: chancePercent,
        },
        players: [
          ...[...Array(nurseryCount).keys()].map((index) => {
            let number = index + 1;
            if (number < 10) {
              number = '0' + number;
            }
            return {
              uid: '00000000-0000-0000-0000-0000000000' + number,
              name: 'Питомник - ' + number,
              role: 'nursery',
            };
          }),
          ...[...Array(shopCount).keys()].map((index) => {
            let number = index + 1 + nurseryCount;
            if (number < 10) {
              number = '0' + number;
            }
            return {
              uid: '00000000-0000-0000-0000-0000000000' + number,
              name: 'Зоомагазин - ' + (index + 1),
              role: 'shop',
            };
          }),
        ],
      },
    );
  }

  createTutorial(chapter, isLTDTraining, isTask) {
    let body;
    if (!isLTDTraining) {
      body = { chapter_num: chapter, is_task: isTask };
    } else {
      body = chapter;
    }
    return this.apiService.post('create_tutorial', { jwt: LocalStorage.USER_TOKEN_TRAINING }, body);
  }

  async getTutorialProgress() {
    return this.apiService.get(`notification/tutorial`, { jwt: LocalStorage.USER_TOKEN_TRAINING });
  }

  async setTutorialProgress(chapter) {
    if (!chapter) {
      return;
    }

    return this.apiService.post(
      `notification/tutorial`,
      { jwt: LocalStorage.USER_TOKEN_TRAINING, game_uuid: LocalStorage.GAME_UID },
      { chapter_num: chapter },
      {},
      true,
      `https://${LocalStorage.JOIN_URL}`,
    );
  }

  async getTutorialLTDProgress() {
    return this.apiService.get(`notification/tutorial_ltd`, { jwt: LocalStorage.USER_TOKEN_TRAINING });
  }

  async setTutorialLTDProgress(chapter) {
    return this.apiService.post(
      `notification/tutorial_ltd`,
      { jwt: LocalStorage.USER_TOKEN_TRAINING, game_uuid: LocalStorage.GAME_UID },
      { chapter_num: chapter },
      {},
      true,
      `https://${LocalStorage.JOIN_URL}`,
    );
  }

  async setDislikeOn(dislikePlayerId) {
    return this.apiService.post(
      `dislike/${LocalStorage.GAME_UID}/${dislikePlayerId}`,
      { jwt: LocalStorage.USER_TOKEN },
      {},
      {},
      false,
      `https://${LocalStorage.JOIN_URL}`,
    );
  }

  async setDislikeOff() {
    return this.apiService.post(
      `dislike/${LocalStorage.GAME_UID}`,
      { jwt: LocalStorage.USER_TOKEN },
      {},
      {},
      false,
      `https://${LocalStorage.JOIN_URL}`,
    );
  }

  async gameExistsOnTheServer(gameUuid) {
    return this.apiService.get(
      `status/${gameUuid}`,
      { jwt: LocalStorage.USER_TOKEN },
      {},
      false,
      `https://${LocalStorage.JOIN_URL}`,
    );
  }
}

export default new Common();

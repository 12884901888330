import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import Modal from '../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../Cat/Cat';
import * as teamMode from '../../../store/teamMode';
import CoinModalIcon from '../../icons/modal/CoinModalIcon';
import routes from '../../../routes';
import groupChildCatsIcon from '../../../assets/group_child_cats_icon.png';
import './styles.scss';
import { LTD_DELEGATED_ACTION_TYPES, ROLE_NAME, TASK_TYPES, TEAM_TASK_STATUS } from '../../../store/constants';
import { CoinIcon } from '../../icons';
import SicknessRingworm from '../../icons/SicknessRingworm';
import SicknessFracture from '../../icons/SicknessFracture';
import SicknessPoisoning from '../../icons/SicknessPoisoning';
import SicknessFleas from '../../icons/SicknessFleas';
import classNames from '../../../common/classNames';
import corpStore from '../../../store/CorpStore';
import { PRIVILEGES, SPECIALITIES } from '../../../common/constants';
import { toJS } from 'mobx';

export const TEAM_TASK_NOTIFICATIONS = {
  TO_REVISION: 'возвращено на доработку отправителю',
  CEO: 'на согласование СЕО',
  CAPTAIN: 'на согласование капитану',
  MARKETEER: 'на согласование маркетологу',
  SUPPLY: 'на согласование закупщику',
  SALES: 'на согласование продажнику',
  DOCTOR: 'доктору на осмотр',
  TASK_INITIATOR: 'стал инициатором сделки',
  NEED_TO_BUY_DRUGS: 'данное лекарство необходимо купить',
};

function TeamTaskNotificationsModal() {
  const { appStore } = RootStore;
  let areaNum;
  const teamTaskNotificationsModalData = appStore.teamTaskNotificationsModalData;
  if (
    !teamTaskNotificationsModalData?.latestAction &&
    !teamTaskNotificationsModalData?.existTask &&
    !teamTaskNotificationsModalData?.nextModal
  ) {
    return null;
  }
  if (appStore.meIsCEO && teamTaskNotificationsModalData.taskType === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST) {
    areaNum = corpStore.areaNumByPlayerId(teamTaskNotificationsModalData.latestAction?.params?.to);
  }
  const { latestAction, taskType, existTask, nextModal, contentType } = teamTaskNotificationsModalData;
  let owner = teamTaskNotificationsModalData?.latestAction?.owner?.title || null;
  let title = teamTaskNotificationsModalData.title || null;
  let desc = null;
  let size = 'cats';
  let currentHouseNumber = null;
  let drugsContent = null;
  let drugsCost = null;
  let colorBg = '';
  const params = latestAction?.params;
  let groupCats = [];
  const isLtdTaskForRevision =
    existTask?.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST &&
    [SPECIALITIES.SUPPLY, SPECIALITIES.SALES].includes(existTask?.stage) &&
    [SPECIALITIES.CAPTAIN, SPECIALITIES.MARKETEER].includes(existTask?.specialty_by) &&
    existTask?.specialty_by === appStore.mySpeciality;

  const isItTraderSpecialities = (specStage, specBy) => {
    return (
      (specStage === SPECIALITIES.SALES || specStage === SPECIALITIES.SUPPLY) &&
      (specBy === SPECIALITIES.SALES || specBy === SPECIALITIES.SUPPLY)
    );
  };
  if (params?.contents) {
    groupCats = params.contents.reduce((acc, cat) => {
      const isItSimilarCats = acc.find((similarCats) => {
        return similarCats[0].gender === cat.gender && similarCats[0].color === cat.color;
      });
      if (isItSimilarCats) {
        isItSimilarCats.push(cat);
      } else {
        acc.push([cat]);
      }
      return acc;
    }, []);
  }
  if (taskType === TASK_TYPES.MATING) {
    let houses;
    let groupedHouses;
    if (appStore.isCorpMode && appStore.meIsCEO) {
      houses = corpStore.allHousesOfCorporation;
      groupedHouses = appStore.groupedHouses[corpStore.areaNumByHouseId(params.house_id)];
    } else {
      houses = appStore.houses;
      groupedHouses = appStore.groupedHouses;
    }
    groupedHouses.find((groupedHouse, index) => {
      if (groupedHouse.left.data.house_id === params.house_id || groupedHouse.right.data.house_id === params.house_id) {
        currentHouseNumber = index + 1;
        return true;
      }
    });
    const currentHouse = houses.find((house) => house.house_id === params.house_id);
    const leftCat = appStore.catById(currentHouse.left_cat_id);
    const rightCat = appStore.catById(currentHouse.right_cat_id);
    groupCats.push(leftCat, rightCat);
  }
  if (taskType === TASK_TYPES.BUY_DRUGS) {
    const drugs = params.drugs;
    drugsCost =
      appStore.gameCosts.treatment *
      Object.values(params.drugs).reduce((acc, drug) => {
        return acc + drug;
      }, 0);

    drugsContent = (
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', paddingTop: '5px' }}>
        {Object.keys(drugs).map((currentDrugName, ind) => {
          if (drugs[currentDrugName] === 0) {
            return null;
          }
          const getCurrentDrugIcon = () => {
            if (currentDrugName === 'salve') {
              return <SicknessRingworm style={{ width: 20, height: 20, fill: '#FDF6EE' }} />;
            } else if (currentDrugName === 'bandage') {
              return <SicknessFracture style={{ width: 20, height: 20, fill: '#FDF6EE' }} />;
            } else if (currentDrugName === 'pills') {
              return <SicknessPoisoning style={{ width: 20, height: 20, fill: '#FDF6EE' }} />;
            } else if (currentDrugName === 'shampoo') {
              return <SicknessFleas style={{ width: 20, height: 20, fill: '#FDF6EE' }} />;
            } else {
              return null;
            }
          };
          const currentDrug = getCurrentDrugIcon();
          if (!currentDrug) {
            return null;
          }
          return (
            <div key={ind} style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{ backgroundColor: '#F57212' }}
                className={classNames({
                  'action-icon__wrapper': true,
                })}
              >
                {currentDrug}
                <span className="action-icon__content-quantity">{'×' + params.drugs[currentDrugName]}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  if (
    latestAction &&
    latestAction.act_name === teamMode.PRIVILEGES.DOCTOR_REQUEST &&
    latestAction.act_name !== teamMode.PRIVILEGES.APPROVE_PURCHASE_TASK_BY_MARKET
  ) {
    title = `отправлено ${TEAM_TASK_NOTIFICATIONS.DOCTOR}`;
  } else if (latestAction && latestAction.type === TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST) {
    title = `отправлено`;
    desc = owner
      ? TEAM_TASK_NOTIFICATIONS[owner.toUpperCase()]
      : appStore.isCorpMode
      ? TEAM_TASK_NOTIFICATIONS.CEO
      : TEAM_TASK_NOTIFICATIONS.CAPTAIN;
  } else if (latestAction && latestAction.act_name !== teamMode.PRIVILEGES.DOCTOR_REQUEST) {
    const dataPrivilege = teamMode.PRIVILEGES_DATA[latestAction.act_name.toUpperCase()];
    let task;
    if (existTask) {
      task = existTask;
    } else if (appStore.isCorpMode) {
      task = {
        stage: dataPrivilege.isMainForSpeciality ? dataPrivilege.isMainForSpeciality : SPECIALITIES.CEO,
        status:
          dataPrivilege?.isMainForSpeciality === SPECIALITIES.CEO
            ? TEAM_TASK_STATUS.CONFIRMED
            : TEAM_TASK_STATUS.IN_PROGRESS,
      };
    } else {
      task = {
        stage: dataPrivilege.isMainForSpeciality ? dataPrivilege.isMainForSpeciality : SPECIALITIES.CAPTAIN,
        status:
          dataPrivilege?.isMainForSpeciality === SPECIALITIES.CAPTAIN
            ? TEAM_TASK_STATUS.CONFIRMED
            : TEAM_TASK_STATUS.IN_PROGRESS,
      };
    }

    const specialityStage = task.stage;

    if (params.to_revision && taskType !== TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
      title = TEAM_TASK_NOTIFICATIONS.TO_REVISION;
    } else {
      if (taskType === TASK_TYPES.CITY_TRADE) {
        if (task.status === TEAM_TASK_STATUS.CONFIRMED) {
          if (params.visa) {
            title = latestAction.params.request_type === 'buy' ? 'покупка у города' : 'продажа городу';
          } else {
            title = latestAction.params.request_type === 'buy' ? 'покупка у города' : 'продажа городу';
            desc = 'отменена';
          }
        } else if (appStore.hasSpecialities([SPECIALITIES.CAPTAIN, SPECIALITIES.CEO]) && !params.visa) {
          title = latestAction.params.request_type === 'buy' ? 'покупка у города' : 'продажа городу';
          desc = 'отменена';
        } else {
          title = 'отправлено';
          desc = TEAM_TASK_NOTIFICATIONS[specialityStage.toUpperCase()];
        }
      } else if (taskType === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
        if (isLtdTaskForRevision) {
          title = TEAM_TASK_NOTIFICATIONS.TO_REVISION;
        } else if (existTask.specialty_by === appStore.mySpeciality) {
          title = `отправлено ${TEAM_TASK_NOTIFICATIONS[existTask.stage.toUpperCase()]}`;
        }
      } else if (taskType === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST) {
        if (task.status === TEAM_TASK_STATUS.CONFIRMED) {
          if (params.visa) {
            title = 'предложение отправлено';
            desc = `в ${ROLE_NAME[appStore.playerByUuid(latestAction.params.to).role]} "${
              appStore.playerByUuid(latestAction.params.to).name
            }"`;
          } else {
            title = 'предложение не согласовано';
          }
        } else if (task.status === TEAM_TASK_STATUS.CANCELLED) {
          title = 'предложение не согласовано';
        } else {
          if (
            existTask.visible_to.includes(appStore.mySpeciality) &&
            specialityStage !== SPECIALITIES.DOCTOR &&
            existTask.specialty_by === appStore.mySpeciality
          ) {
            title = 'отправлено';
            desc = TEAM_TASK_NOTIFICATIONS[specialityStage.toUpperCase()];
          }
        }
      } else if (taskType === TASK_TYPES.CANCEL_OWN_TRADE_REQUEST) {
        if (task.status === TEAM_TASK_STATUS.CONFIRMED) {
          desc = `${params.request_type === 'buy' ? 'покупка из' : 'продажа в'} "${
            latestAction.params.to ?? appStore.playerByUuid(latestAction.params.to).name
          }"`;
          title = `сделка отменена`;
          colorBg = 'red';
        } else if (task.status === TEAM_TASK_STATUS.IN_PROGRESS) {
          title = `отправлено ${TEAM_TASK_NOTIFICATIONS.CAPTAIN}`;
        }
      } else if (taskType === TASK_TYPES.BUY_DRUGS) {
        if (task.status !== TEAM_TASK_STATUS.CONFIRMED && task.status !== TEAM_TASK_STATUS.CANCELLED) {
          if (appStore.isCorpMode && appStore.hasSpecialities(SPECIALITIES.CAPTAIN)) {
            if (params.visa) {
              title = `отправлено ${TEAM_TASK_NOTIFICATIONS.CEO}`;
            } else {
              appStore.hideTeamTaskNotificationsModal();
              return;
            }
          } else {
            title = `отправлено ${TEAM_TASK_NOTIFICATIONS.CAPTAIN}`;
          }
        } else {
          title = params.visa ? 'согласовано' : 'не согласовано';
        }
      } else {
        params.contents = null;
        if (task.status !== TEAM_TASK_STATUS.CONFIRMED && task.status !== TEAM_TASK_STATUS.CANCELLED) {
          if (appStore.isCorpMode && appStore.hasSpecialities(SPECIALITIES.CAPTAIN)) {
            if (params.visa) {
              title = `отправлено ${TEAM_TASK_NOTIFICATIONS.CEO}`;
            } else {
              appStore.hideTeamTaskNotificationsModal();
              return;
            }
          } else {
            title = `отправлено ${TEAM_TASK_NOTIFICATIONS.CAPTAIN}`;
          }
        } else {
          appStore.hideTeamTaskNotificationsModal(); // для каждого оставшегося task_type написать title
        }
      }
    }
  } else if (
    existTask &&
    ((existTask.stage !== existTask.specialty_by && !isItTraderSpecialities(existTask.stage, existTask.specialty_by)) ||
      !appStore.hasSpecialities([existTask.stage]))
  ) {
    if (
      existTask.type === TASK_TYPES.CITY_TRADE &&
      !(
        appStore.myPrivileges.includes(PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN) ||
        appStore.myPrivileges.includes(PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN)
      )
    ) {
      if (existTask.status === TEAM_TASK_STATUS.IN_PROGRESS) {
        title = 'отправлено';
        desc = TEAM_TASK_NOTIFICATIONS[existTask.stage.toUpperCase()];
      } else {
        title = `${existTask.content.request_type === 'buy' ? 'покупка у города' : 'продажа городу'} ${
          existTask.status === TEAM_TASK_STATUS.CONFIRMED ? 'согласована' : 'отменена'
        }`;
      }
    } else if (existTask.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
      if (isLtdTaskForRevision) {
        title = TEAM_TASK_NOTIFICATIONS.TO_REVISION;
      } else if (existTask.specialty_by === appStore.mySpeciality) {
        title = `отправлено ${TEAM_TASK_NOTIFICATIONS[existTask.stage.toUpperCase()]}`;
      }
    } else if (existTask.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST) {
      if (existTask.status === TEAM_TASK_STATUS.IN_PROGRESS) {
        if (appStore.mySpeciality === existTask.stage) {
          if (
            existTask.visible_to.find((spec) => spec === SPECIALITIES.DOCTOR) &&
            Object.keys(existTask.visas).find((spec) => spec === SPECIALITIES.DOCTOR)
          ) {
            title = 'доктор осмотрел котиков. Можно отправить задачу дальше';
          } else if (
            (existTask.specialty_by === SPECIALITIES.CAPTAIN || existTask.specialty_by === SPECIALITIES.MARKETEER) &&
            existTask.visible_to.includes(appStore.mySpeciality)
          ) {
            title = 'поступила задача на доработку';
          }
        } else if (
          (existTask.specialty_by === SPECIALITIES.CAPTAIN || existTask.specialty_by === SPECIALITIES.MARKETEER) &&
          existTask.visible_to.includes(appStore.mySpeciality) &&
          existTask.created_by === localStorage.USER_UID
        ) {
          title = 'поступила задача на доработку';
        } else if (
          existTask.specialty_by === SPECIALITIES.MARKETEER &&
          existTask.visible_to.includes(appStore.mySpeciality) &&
          appStore.mySpeciality === SPECIALITIES.MARKETEER &&
          existTask.stage !== SPECIALITIES.MARKETEER
        ) {
          title = TEAM_TASK_NOTIFICATIONS.TO_REVISION;
        }
      } else if (existTask.status === TEAM_TASK_STATUS.CONFIRMED || existTask.status === TEAM_TASK_STATUS.CANCELLED) {
        title = `${existTask.content.request_type === 'buy' ? 'покупка' : 'продажа'} ${
          existTask.status === TEAM_TASK_STATUS.CONFIRMED ? 'согласована' : 'отменена'
        }`;
      }
    } else if (existTask.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
      if (existTask.status === TEAM_TASK_STATUS.IN_PROGRESS) {
        title = 'поступила задача на доработку';
      } else {
        title =
          existTask.status === TEAM_TASK_STATUS.CONFIRMED ? 'изменение цен согласовано' : 'изменение цен отменено';
      }
    } else if (existTask?.type === TASK_TYPES.GET_CREDIT) {
      title = `взятие кредита ${existTask?.status === TEAM_TASK_STATUS.CANCELLED ? 'не одобрено' : 'одобрено'}`;
    } else if (existTask?.type === TASK_TYPES.CREDIT_REPAY) {
      title = `досрочное погашение кредита ${
        existTask?.status === TEAM_TASK_STATUS.CANCELLED ? 'не одобрено' : 'одобрено'
      }`;
    } else if (existTask.type === TASK_TYPES.BUY_HOUSE_INSURANCE) {
      const numberOfHouse = appStore.groupedHouses.find((groupHouse) => {
        return groupHouse.left.data.house_id === existTask.content.house_ids[0];
      }).index;
      title = `страхование домика №${numberOfHouse + 1} \n ${
        existTask.status === TEAM_TASK_STATUS.CANCELLED ? 'не одобрено' : 'одобрено'
      }`;
    } else if (existTask.type === TASK_TYPES.BUY_DRUGS) {
      title = `покупка лекарств \n ${existTask.status === TEAM_TASK_STATUS.CANCELLED ? 'не одобрена' : 'одобрена'}`;
    } else if (existTask.type === TASK_TYPES.MATING) {
      title = `скрещивание \n ${existTask.status === TEAM_TASK_STATUS.CANCELLED ? 'не одобрено' : 'одобрено'}`;
    } else if (existTask.type === TASK_TYPES.BUY_FOOD) {
      title = `покупка корма \n ${existTask.status === TEAM_TASK_STATUS.CANCELLED ? 'не одобрена' : 'одобрена'}`;
    } else if (existTask.type === TASK_TYPES.BUY_HOUSE) {
      title = `покупка домика \n ${existTask.status === TEAM_TASK_STATUS.CANCELLED ? 'не одобрена' : 'одобрена'}`;
    } else {
      appStore.hideTeamTaskNotificationsModal();
    }
  } else if (nextModal) {
  } else if (
    existTask.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST &&
    existTask.visible_to.includes(appStore.mySpeciality) &&
    existTask.stage === appStore.mySpeciality &&
    existTask.specialty_by === appStore.mySpeciality &&
    existTask.specialty_by === !appStore.meIsCEO
  ) {
    title = `отправлено ${TEAM_TASK_NOTIFICATIONS.DOCTOR}`;
  }

  if (
    existTask &&
    existTask.status === TEAM_TASK_STATUS.CANCELLED &&
    existTask.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST &&
    existTask.stage === appStore.mySpeciality &&
    existTask.specialty_by
  ) {
    title = 'сделка отменена';
  }

  if (!title) {
    appStore.hideTeamTaskNotificationsModal();
    return;
  }
  let insuranceContent = null;
  if (latestAction && taskType === TASK_TYPES.BUY_HOUSE_INSURANCE) {
    size = 'big';
    const numberOfHouse = appStore.groupedHouses.find((groupHouse) => {
      return groupHouse.left.data.house_id === params.house_ids[0];
    }).index;
    desc = `ДОМИК №${numberOfHouse + 1}`;

    insuranceContent = (
      <div className="insurance-content">
        {params.house_ids.map((houseId, ind) => {
          const currentHouse = appStore.houses.find((house) => house.house_id === houseId);
          let leftSideCat = appStore.catById(currentHouse.left_cat_id);
          if (!leftSideCat) {
            leftSideCat = { count: 0 };
          }
          let rightSideCat = appStore.catById(currentHouse.right_cat_id);
          if (!rightSideCat) {
            rightSideCat = { count: 0 };
          }
          const isLeftHouseMating = leftSideCat?.mating_status === 'mating';
          return (
            <div key={ind} className="insurance-content__item">
              <div className="insurance-content__adult-cats modal__body-cats--each-other-look">
                <Cat
                  cat={leftSideCat}
                  state={leftSideCat.color ? CAT_STATE.SINGLE : CAT_STATE.ABSTRACT}
                  bottomText={BOTTOM_TEXT.AGE}
                  readonly={true}
                  notShowCount={!leftSideCat.color}
                />
                <Cat
                  cat={rightSideCat}
                  state={rightSideCat.color ? CAT_STATE.SINGLE : CAT_STATE.ABSTRACT}
                  bottomText={BOTTOM_TEXT.AGE}
                  readonly={true}
                  notShowCount={!rightSideCat.color}
                />
              </div>
              <div className="insurance-content__child-cats">
                {!isLeftHouseMating ? (
                  <img src={groupChildCatsIcon} alt="group_child_cats_icon" />
                ) : (
                  <p>скоро появятся котята</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const meIsDoctorAndFakeDoctorReviewRequest =
    (appStore.isCorpMode || appStore.isRealTeamMode) &&
    !appStore.meIsCEO &&
    appStore.hasSpecialities(SPECIALITIES.DOCTOR) &&
    latestAction?.type === TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST;

  return (
    <Modal
      isOpen={!!appStore.teamTaskNotificationsModalData}
      onClose={appStore.hideTeamTaskNotificationsModal}
      desc={!!areaNum && desc ? `${desc} город ${areaNum}` : desc}
      title={title}
      colorBg={colorBg}
      size={size}
    >
      <div className="modal__body-wrapper">
        {!!insuranceContent && insuranceContent}
        {latestAction && !params.to_revision && params.contents && taskType !== TASK_TYPES.CREATE_CHANGE_LTD_REQUEST && (
          <>
            <div className="modal__body-cats">
              {groupCats.map((similarCats, index) => {
                let abstractCat;
                const existCats = [];
                similarCats.forEach((cat) => {
                  if (cat.detail || cat.cat_id) {
                    existCats.push(cat);
                  } else {
                    abstractCat = cat;
                  }
                });
                const hasAbstractCat = !!abstractCat;
                let cat;
                if (existCats.length) {
                  cat = existCats[0];
                } else {
                  cat = abstractCat;
                }
                let customCount;
                if (meIsDoctorAndFakeDoctorReviewRequest) {
                  customCount = similarCats[0].factCount;
                  if (customCount === 0) {
                    similarCats[0].count = 0;
                  }
                } else if (similarCats.length > 1) {
                  customCount = existCats.length;
                  if (hasAbstractCat) {
                    customCount = customCount + abstractCat.count;
                  }
                } else {
                  customCount = 0;
                }
                return (
                  <Cat
                    key={`modal__body-cats--${index}`}
                    readonly={true}
                    cat={cat}
                    bottomText={BOTTOM_TEXT.KIND}
                    state={CAT_STATE.SINGLE}
                    realCount={true}
                    customCount={customCount}
                  />
                );
              })}
            </div>
            {!meIsDoctorAndFakeDoctorReviewRequest && (
              <div style={{ marginBottom: '10px' }} className="team-notification-price">
                <div className="modal__body-price-text team-notification-price__title">
                  {taskType === TASK_TYPES.CITY_TRADE
                    ? params.request_type === 'buy'
                      ? 'цена покупки'
                      : 'цена продажи'
                    : 'итого цена'}
                </div>
                <div className="team-notification-price__cost">
                  <CoinModalIcon className="modal__body-price-coin-icon" />
                  <span className="modal__body-price-coin-count">
                    {params.contents.reduce((sum, cat) => sum + cat.price * cat.count, 0)}
                  </span>
                </div>
              </div>
            )}
          </>
        )}
        {latestAction && taskType === TASK_TYPES.MATING && (
          <>
            <div className="modal__body-cats">
              {groupCats.map((cat) => {
                return (
                  <Cat
                    key={cat.gender + cat.color}
                    readonly={true}
                    cat={cat}
                    bottomText={BOTTOM_TEXT.KIND}
                    state={CAT_STATE.SINGLE}
                    realCount={true}
                    customCount={1}
                  />
                );
              })}
            </div>
            <div className="task-constructor__content-mating">скрестить пару в домике №{currentHouseNumber}</div>
          </>
        )}
        {latestAction && taskType === TASK_TYPES.BUY_DRUGS && (
          <>
            {drugsContent}
            <div style={{ marginTop: '16px', marginBottom: '18px' }} className="task-constructor__content-price">
              итого цена
              <span className="task-constructor__content-price--coin">
                <CoinIcon
                  style={{
                    verticalAlign: 'middle',
                    marginRight: '4px',
                    width: '24px',
                    height: '24px',
                  }}
                />
                {drugsCost}
              </span>
            </div>
          </>
        )}
      </div>
      <div className="modal__body-actions">
        {nextModal &&
          (contentType ? (
            <>
              <TextButton color="purple" title="Отмена" onClick={appStore.hideTeamTaskNotificationsModal} />
              <TextButton
                color="green"
                title="Купить"
                onClick={() => {
                  appStore.hideTeamTaskNotificationsModal();
                  nextModal(contentType);
                }}
              />
            </>
          ) : (
            <TextButton color="blue" title="Понятно" onClick={appStore.hideTeamTaskNotificationsModal} />
          ))}

        {!latestAction && existTask && existTask.type !== TASK_TYPES.CREATE_CHANGE_LTD_REQUEST ? (
          <>
            <TextButton
              color="purple"
              title="Посмотреть"
              onClick={() => {
                if (
                  existTask.status === TEAM_TASK_STATUS.CONFIRMED ||
                  existTask.status === TEAM_TASK_STATUS.CANCELLED
                ) {
                  if (existTask.content) {
                    const relatedLotId = existTask.content.lot_id;
                    if (relatedLotId && appStore.lotById(relatedLotId)) {
                      appStore.hideLot(relatedLotId);
                    }
                  }
                }
                appStore.setCurrentTask(existTask.task_id);
                appStore.hideTeamTaskNotificationsModal();
                if (
                  existTask.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST ||
                  existTask.type === TASK_TYPES.CANCEL_OWN_TRADE_REQUEST
                ) {
                  RootStore.router.goTo(routes.inside, { uuid: appStore.currentPlayer.uuid });
                }
              }}
            />
            <TextButton color="blue" title="Понятно" onClick={appStore.hideTeamTaskNotificationsModal} />
          </>
        ) : (
          <TextButton color="blue" title="Понятно" onClick={appStore.hideTeamTaskNotificationsModal} />
        )}
      </div>
    </Modal>
  );
}

export default observer(TeamTaskNotificationsModal);

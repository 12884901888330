import React from 'react';
import { MobxRouter } from 'mobx-router';

import { isIE } from 'react-device-detect';

import store from './store';
import './ui/global.scss';
import 'slick-carousel/slick/slick.css';
import EndTurnModal from './ui/components/EndTurnModal';
import Training from './ui/components/Training';
import SeasonChangeModal from './ui/components/SeasonChangeModal';
import CatsAwayModal from './ui/components/CatsAwayModal';
import ExactlyEndTurnModal from './ui/components/ExactlyEndTurnModal';
import NotEnoughBalanceModal from './ui/components/NotEnoughBalanceModal';
import BuyHouseInsuranceModal from './ui/components/BuyHouseInsuranceModal';
import NotEnoughCatsModal from './ui/components/NotEnoughCatsModal';
import BuyHouseModal from './ui/components/BuyHouseModal';
import CatUnderMagnifierModal from './ui/components/CatUnderMagnifierModal';
import LotProposalSentModal from './ui/components/LotProposalSentModal';
import NotificationModal from './ui/components/NotificationModal';
import UnacceptableTreatmentModal from './ui/components/UnacceptableTreatmentModal';
import FleasModal from './ui/components/FleasModal';
import FractureModal from './ui/components/FractureModal';
import PoisoningModal from './ui/components/PoisoningModal';
import RingwormModal from './ui/components/RingwormModal';
import UserListModal from './ui/components/UserLists/UserListModal';
import SessionUsersList from './ui/components/SessionUsersList/SessionUsersList';
import { UserGuideModalButton } from './ui/components/UserGuideModal/UserGuideModal';
import UserGuideModal from './ui/components/UserGuideModal/UserGuideModal';
import BalanceModal from './ui/components/BalanceModal';
import CreditModal from './ui/components/CreditModal';
import CreditNotificationModal from './ui/components/Credit/CreditNotificationModal';
import EarlyRepaymentModal from './ui/components/EarlyRepaymentModal';
import LTDLiabilityModal from './ui/components/LTDLiabilityModal/LTDLiabilityModal';
import LTDMakingModal from './ui/components/LTDMakingModal/LTDMakingModal';
import GameOverModal from './ui/components/GameOverModal';
import GameOverManagerModal from './ui/components/GameOverManagerModal';
import BankruptcyModal from './ui/components/BankruptcyModal';
import NetworkTimeoutModal from './ui/components/NetworkTimeoutModal';
import ParametersModal from './ui/components/ParametersModal';
import TrainingTask from './ui/components/TrainingTask';
import CatIsSicknessOrHungryModal from './ui/components/CatIsSicknessOrHungryModal';
import CantFeedModal from './ui/components/CantFeedModal';
import SpectatorModal from './ui/components/SpectatorModal';
import DragCatMomModal from './ui/components/DragCatMomModal';
import CantTreatmentModal from './ui/components/CantTreatmentModal';
import ExportOnMainScreenModal from './ui/components/ExportOnMainScreenModal';
import GroupedCatModal from './ui/components/GroupedCatModal';
import BuyFromCityModal from './ui/components/BuyFromCityModal';
import SellToCityModal from './ui/components/SellToCityModal';
import PhoneTurnModal from './ui/components/PhoneTurnModal/PhoneTurnModal';
import MissingHouseModal from './ui/components/MissingHouseModal';
import NotEnoughSpaceHouseModal from './ui/components/NotEnoughSpaceHouseModal';
import Messenger from './chat/Messenger';
import SocketConnectingModal from './ui/components/SocketConnectingModal';
import IntroductionModal from './ui/components/IntroductionModal';
import UnsupportedBrowserModal from './ui/components/UnsupportedBrowserModal';
import Loader from './ui/components/Loader/Loader';
import usePreload from './preloader';
import BuyEatModal from './ui/components/TeamDoctorModals/BuyEatModal';
import CurrentTaskModal from './ui/components/CurrentTaskModal/CurrentTaskModal';
import TeamTaskNotificationsModal from './ui/components/TeamNotificationsModal/TeamTaskNotificationsModal';
import TeamDelegationNotificationsModal from './ui/components/TeamNotificationsModal/TeamDelegationNotificationsModal';
import DelegatingModal from './ui/components/DelegatingModal/DelegatingModal';
import DelegatingInfoModal from './ui/components/DelegatingModal/DelegatingInfoModal';
import PlayerTimedOutForCaptainModal from './ui/components/PlayerTimedOutForCaptainModal';
import CaptainTimedOutModal from './ui/components/CaptainTimedOutModal';
import TeamHistoryModal from './ui/components/TeamHistoryModal/TeamHistoryModal';
import NotEnoughFoodModal from './ui/components/NotEnoughFoodModal';
import BuyTreatmentModal from './ui/components/TeamDoctorModals/BuyDrugsModal';
import TeamEstimationModal from './ui/components/TeamEstimationModal/TeamEstimationModal';
import RightPanel from './ui/components/RightPanel/RightPanel';
import NotEnoughCatsForMagnifierModal from './ui/components/NotEnoughCatsForMagnifierModal';
import SellCatFromParentWindowModal from './ui/components/SellCatFromParentWindowModal';
import NotificationAboutBlockingModal from './ui/components/NotificationAboutBlockingModal';
import LTDCancelModal from './ui/components/LTDCancelModal/LTDCancelModal';
import LTDBankGuaranteeModal from './ui/components/LTDBankGuaranteeModal/LTDBankGuaranteeModal';
import LTDTerminationModal from './ui/components/LTDTerminationModal/LTDTerminationModal';
import LTDNotificationsModal from './ui/components/LTDNotificationsModal/LTDNotificationsModal';
import LTDIncompleteStorageModal from './ui/components/LTDIncompleteStorageModal/LTDIncompleteStorageModal';
import AutoTerminationLTDModal from './ui/components/AutoTerminationLTDModal';
import SendLTDOverdueFineModal from './ui/components/SendLTDOverdueFineModal';
import CorporationModal from './ui/components/CorporationModal/CorporationModal';
import BudgetAllocationModal from './ui/components/BudgetAllocationNotificationModal';
import BudgetAllocationConfirmationModal from './ui/components/BudgetAllocationConfirmationModal';
import MyTasksModal from './ui/components/MyTasksModal/MyTasksModal';
import TransferCatsModal from './ui/components/CORP/TransferCatsModal/TransferCatsModal';
import ConfirmationActionWithCarModal from './ui/components/ConfirmationActionWithCarModal/ConfirmationActionWithCarModal';
import LimitModal from './ui/components/LimitModal/LimitModal';
import ImportPaymentModal from './ui/components/ImportPaymentModal';
import ReceivingCarsModal from './ui/components/CORP/ReceivingCarsModal/ReceivingCarsModal';
import MovingCatsModal from './ui/components/CORP/MovingCatsModal/MovingCatsModal';
import ImportExportNotificationModal from './ui/components/ImportExportNotificationModal';
import CurrentSeasonLimitsModal from './ui/components/CORP/CurrentSeasonLimitsModal/CurrentSeasonLimitsModal';
import NotEnoughSeasonForTransfer from './ui/components/CORP/NotEnoughSeasonForTransfer/NotEnoughSeasonForTransfer';
import NotEnoughSeasonForTransportation from './ui/components/CORP/NotEnoughSeasonForTransportation/NotEnoughSeasonForTransportation';
import OtherPriceCityQuota from './ui/components/OtherPriceCityQuota';
import LTDArchiveModal from './ui/components/LTDАrchiveModal/LTDArchiveModal';
import LogDownloaderButton from './ui/components/LogDownloaderButton';
import NoSuchCatModal from './ui/components/NoSuchCatModal';
import SoloModeTester from './ui/components/SoloModeTester/SoloModeTester';
import TeamModeTester from './ui/components/teamModeTester/TeamModeTester';
import CorpModeTester from './ui/components/CorpModeTester/CorpModeTester';
import SwiperCore, { Virtual } from 'swiper';
import classNames from './common/classNames';
import RootStore from './store';
import { observer } from 'mobx-react';
import TechnicalWorkInProgressModal from './ui/components/TechnicalWorkInProgress';
import OurCompanyUserListModal from './ui/components/UserLists/OurCompanyUserListModal/OurCompanyUserListModal';
import SelectUserListModal from './ui/components/UserLists/SelectUserListModal';
import trainingStore from './store/trainingStore';
import Chapter from './store/trainings/base/Chapter';
import ExactlyPauseGameModal from './ui/components/Pause/ExactlyPauseGameModal';
import PauseGameModal from './ui/components/Pause/PauseGameModal';
import TheDealWasTakenAwayModal from './ui/components/TheDealWasTakenAwayModal';
import { OnlyLtdAllowedModal } from './ui/components/LTDOnlyModal/OnlyLtdAllowedModal';
import TutorialTimeOverModal from './ui/components/TutorialTimeOverModal';
import PullingCatOutHouseModal from './ui/components/PullingCatOutHouseModal';
import WaitEndTurnModal from './ui/components/WaitEndTurnModal';
import FeedAllCats from './ui/components/FeedAllCats';

SwiperCore.use([Virtual]);

const App = (props) => {
  const { loading } = usePreload();
  const { appStore } = RootStore;

  if (isIE)
    return (
      <div
        className="app-layout"
        id="appLayout"
        style={{ background: 'url(./src/assets/fake-bg.png)', backgroundSize: 'cover' }}
      >
        <UnsupportedBrowserModal />
      </div>
    );

  if (
    (loading && !props.disableLoader) ||
    (trainingStore.launched && trainingStore.chapter instanceof Chapter && !appStore.appIsInitiated)
  ) {
    return <Loader />;
  }

  return (
    <div
      className={classNames({
        'app-layout': true,
        [`season--${appStore.currentSeasonType}`]: !trainingStore.launched,
      })}
      id="appLayout"
    >
      <MobxRouter store={store} />
      <PhoneTurnModal />
      <RightPanel />
      <BuyHouseInsuranceModal />
      <NotEnoughFoodModal />
      <NotEnoughCatsModal />
      <NoSuchCatModal />
      <OtherPriceCityQuota />
      <LotProposalSentModal />
      <BalanceModal />
      <SellCatFromParentWindowModal />
      <CreditModal />
      <CreditNotificationModal />
      <EarlyRepaymentModal />
      <MyTasksModal />
      <LTDLiabilityModal />
      <LTDCancelModal />
      <LTDBankGuaranteeModal />
      <LTDTerminationModal />
      <LTDMakingModal />
      <LTDNotificationsModal />
      <LTDIncompleteStorageModal />
      <LTDArchiveModal />
      <SendLTDOverdueFineModal />
      <ImportExportNotificationModal />
      <ImportPaymentModal />
      <NotEnoughBalanceModal />
      <NotEnoughSeasonForTransfer />
      <NotEnoughSeasonForTransportation />
      <NotificationAboutBlockingModal />
      <NotificationModal />
      <ExactlyEndTurnModal />
      <EndTurnModal />
      <ExactlyPauseGameModal />
      <PauseGameModal />
      <BuyHouseModal />
      <MissingHouseModal />
      <TechnicalWorkInProgressModal />
      <NotEnoughSpaceHouseModal />
      <CatsAwayModal />
      <AutoTerminationLTDModal />
      <SeasonChangeModal />
      <UnacceptableTreatmentModal />
      <FleasModal />
      <FractureModal />
      <PoisoningModal />
      <RingwormModal />
      <UserListModal />
      <SessionUsersList />
      <CantFeedModal />
      <DragCatMomModal />
      <ParametersModal />
      <CantTreatmentModal />
      <ExportOnMainScreenModal />
      <GroupedCatModal />
      <CatIsSicknessOrHungryModal />
      <BuyFromCityModal />
      <ReceivingCarsModal />
      <MovingCatsModal />
      <SellToCityModal />
      <SoloModeTester />
      <TeamModeTester />
      <CorpModeTester />
      <BuyTreatmentModal />
      <BuyEatModal />
      <OurCompanyUserListModal />
      <SelectUserListModal />
      <CurrentTaskModal />
      <TeamTaskNotificationsModal />
      <TeamDelegationNotificationsModal />
      <DelegatingModal />
      <DelegatingInfoModal />
      <CorporationModal />
      <TransferCatsModal />
      <ConfirmationActionWithCarModal />
      <BudgetAllocationModal />
      <BudgetAllocationConfirmationModal />
      <PlayerTimedOutForCaptainModal />
      <CaptainTimedOutModal />
      <TeamHistoryModal />
      <CatUnderMagnifierModal />
      <NotEnoughCatsForMagnifierModal />
      <GameOverModal />
      <TeamEstimationModal />
      <GameOverManagerModal />
      <TutorialTimeOverModal />
      <NetworkTimeoutModal />
      <BankruptcyModal />
      <SocketConnectingModal />
      <LimitModal />
      <CurrentSeasonLimitsModal />
      <Training />
      <TrainingTask />
      <SpectatorModal />
      <UserGuideModal />
      <UserGuideModalButton />
      <IntroductionModal />
      <Messenger />
      <LogDownloaderButton />
      <TheDealWasTakenAwayModal />
      <OnlyLtdAllowedModal />
      <PullingCatOutHouseModal />
      <WaitEndTurnModal />
      <FeedAllCats />
    </div>
  );
};

export default observer(App);
